import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';

function DailyDeliverySheet() {
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const MySwal = withReactContent(Swal);
  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

   //below declaration of variable 'previousCenterId' to not show same centerid if it is 
  //same in next loop also only show once.
  let previousCenterIdTable = null;
  let previousCenterIdPrint = null;

  //below variables to show total at last of page in table
  let totalqtytable= 0;
  let totalratetable= 0;
  let totalamttable= 0;
  let totalprevbaltable= 0;
  let totalreceipttable= 0;
  let totalcurrent_balancetable= 0;

  //below variables to show total at last of page in print
  let totalqtyprint= 0;
  let totalrateprint= 0;
  let totalamtprint= 0;
  let totalprevbalprint= 0;
  let totalreceiptprint= 0;
  let totalcurrent_balanceprint= 0;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
        if(response.data == "logout") {
          navigatetologin();
        }
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  }

    const [CustomerMasterData, setCustomerMasterData] = useState([]);

    const [errors, seterrors] = useState({});

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = {
        ...formData,
        [name]: value,
      };

      if(name == "centerid") {
        const [centerid, centername] = value.split('-');
        updatedFormData.centerno = centerid;
        setdefaultcenter(centerid);
        // getroutemasterdata(centerid, 'd6');
        getroutemasterdatabycenterid(centerid, "centername");
      }

      if(name == "routename") {
        const [routecode, routename] = value.split('-');
        // updatedFormData.routecode = routecode;
        setdefaultroutename(routecode);
      }

      setFormData(updatedFormData);
    };

    const [formData, setFormData] = useState({
      centerno: '',
      routecode: '',
      month: '',
      centername: '',
      routename: '',
      centerid: '',
      customer_centerwiseid: '',
      customer_name: '',
      transactionid: '',
      customer_status: '',
    });

    const [defaultcenter, setdefaultcenter] = useState();
    const [CenterMasterData, setCenterMasterData] = useState([]);
    //2) using axios
    useEffect(() => {
    // Fetch data from the specified URL
      if (UserMasterDataGlobal.length > 0) {
        axios.get(apireq05042024+`CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)
  
        .then((response) => {
          if(!defaultcenter) {
            setCenterMasterData(response.data.CenterMasterData);
            setdefaultcenter(UserMasterDataGlobal?.[0]?.centerid);
            setFormData({
              ...formData,
              centerno: UserMasterDataGlobal?.[0]?.centerid,
            })
            getroutemasterdata(UserMasterDataGlobal?.[0]?.centerid, '2d');
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
      }
    }, [UserMasterDataGlobal]);

    const getCenterNameById = (centerid) => {
      const center = CenterMasterData.find(center => center.centerid === centerid);
      return center ? center.centername : '';
    };

    const [defaultroutename, setdefaultroutename] = useState();
    const [RouteMasterData, setRouteMasterData] = useState([]);

    const routecodeonblur = async (e) => {
      e.preventDefault();
      var { name, value } = e.target;
  
      if (value.includes('-')) {
        // Split the value based on '-'
        const [routecode, routename] = value.split('-');
        value = routecode;
      }
  
      if(value == ''){
        return;
      }
  
      const response = await axios.get(apireq05042024+'RouteMaster/getroutenamebyroutecode?routecode='+value+'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId)
   
      .then((response) => {
        // Access the response data directly using response.data       
        try {
          // formData.routename = response.data.customer_name_data[0].routename;
          setdefaultroutename(response.data.routenamedata[0].routename);
        }
        catch{

        }
        
      });   
    };
  
    const centernoonblur = async (e) => {
      e.preventDefault();
      var { name, value } = e.target;

      if(value == ''){
        return;
      }
      setdefaultcenter(value);
      formData.centerid = value;
      // getroutemasterdata(value, 'd1');
      getroutemasterdatabycenterid(value);
    };

  async function viewonclick (event) {
    // function regeneraterecords(event) {
      event.preventDefault();     
      
      temporaryvalues_table();
      
  }

  async function temporaryvalues_table(toshowcustomermasterdata = null) {

      var validationerrors = {};

      if(defaultroutename !== '') {
      }else{
        validationerrors.routecode = "Please select prefered line no from list.";
      } 

      if(formData.centerno !== '') {
      }else{
        validationerrors.centerno = "Please select prefered center from list.";
      } 
      
      if(formData.month !== '') {
      }else{
        validationerrors.month = "Please select date from datepicker.";
      } 
      const [year, month] = formData.month.split('-');
      seterrors(validationerrors);

      if(Object.keys(validationerrors).length == 0) {
      
        var data = {
          centerno:formData.centerno,
          routecode: defaultroutename,
          month: month,
          year: year,
          CompanyId: UserMasterDataGlobal?.[0]?.CompanyId || "",
          userid: UserMasterDataGlobal?.[0]?.userid || "",
          SearchWithoutRoutename: SearchWithoutRoutename
        };
        // alert(data.routecode + " at step1");
       
        setFormData({
          ...formData,
          centerno: data.centerno,
          routecode: data.routecode
        });
        // alert(data.routecode + " at step2");
        // return;
        setdefaultcenter(data.centerno);
        setdefaultroutename(data.routecode);

        MySwal.fire({
          title: 'Generating Report',
          text: 'Please wait while the report is being generated.',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        try {
          var response  = "";
          if(toshowcustomermasterdata !== "notshow") {
            response = await axios.post(apireq05042024+'Reports/MonthlyCustomerWiseReport', data);
            setCustomerMasterData([]);  
            if (response.status === 200) {
              // console.log(response.data);
              // return;    
              setCustomerMasterData(response.data);   
              MySwal.close();

            }   
          }
        } catch {      
        } 
      }  
  }

  const { state } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (state) {
          setFormData({
            ...formData,
            centerno: state?.centerid,
            routecode: state?.routecode
          });  
          // formref.current.submit(); 
          // console.log('s');
          // if (buttonref.current) {
          Swal.fire("Click on view button to see the latest record added!");
          // }
        }

      } catch (error) {
        console.error('Error fetching data for editing:', error);
      }
    };

    fetchData();
  }, [state]);
    
  // const [temporaryvalues_table_data, settemporaryvalues_table_data] = useState([]);
  
  useEffect(() => {
    if (UserMasterDataGlobal.length > 0) {
      
      const userIdapireq = UserMasterDataGlobal[0]?.userid;
      const companyIdapireq = UserMasterDataGlobal[0]?.CompanyId;
      
      const apirequest = apireq05042024+`DailyDeliverySheet/gettemporaryvalues_tabledata?CompanyId=${companyIdapireq}&userid=${userIdapireq}`;

      axios.get(apirequest)
        .then((response) => {
          try {
            if(!defaultcenter) {
              setdefaultroutename(response.data[0].text3);
              setdefaultcenter(response.data[0].text1);

              setFormData({
                ...formData,
                routecode: response.data[0].text3,
                centerno: response.data[0].text1,
              });
            }
            
          }
          catch {

          }          

        })

        .catch((error) => console.error('Error fetching route data:', error));
    }
  }, [UserMasterDataGlobal]);

  async function getroutemasterdata(centerid, rr) {
    // console.log(rr);
    var data1 = {
      centerid : centerid,
      CompanyId : UserMasterDataGlobal[0]?.CompanyId,
    }
    // console.log(data1);
    var response1 = axios.get(apireq05042024+`RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
    .then((response1) => {
      setdefaultroutename(response1.data[0].routename);
      setRouteMasterData(response1.data);
    })
  }

    //to get the data from centerid/centerno we use this function
    async function getroutemasterdatabycenterid(centerid, rr=null) {
      var data1 = {
          centerid : centerid,
          CompanyId : UserMasterDataGlobal[0]?.CompanyId,
      }
      var response1 = axios.get(apireq05042024+`RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
      .then((response1) => {
          setdefaultroutename(response1.data[0].routename);
          setRouteMasterData(response1.data);
          
      })
  }

    //These variables declared here to show Total of Qty and Amt at the last of table.
    var TotalOutStanding =0;
    var TotalCustomerCount =0;

  const getDateMonth = (month) => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return months[month - 1]; // Subtract 1 to match array indexing
  };

  async function printContent(event) {
    event.preventDefault();
  
    await temporaryvalues_table();
  
    const printWindow = window.open('', '_blank');
    const [year, month] = formData.month.split('-');
    const month1 = getDateMonth(month);

    printWindow.document.write(`
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
      <div class="table-container col-md-12 ">
        <h1 style="color: red; font-size: 30px; font-weight: bold; margin-top: 5px; text-align: center;">Monthly CustomerWise Report</h1>
        <h2 style="color: red; font-size: 20px; font-weight: bold; margin-top: 5px; text-align: center;">${month1}-${year}</h1>
        <span style="text-align: left; font-size: large;">Center Name: ${getCenterNameById(formData.centerno)}</span>
        <br>
        <span style="text-align: left; font-size: large;">Line No: ${formData.routecode}</span>
        
        <table class="table table-bordered mt-3">
          <thead>
            <tr>
              <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle">Customer Name</th>
              <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle;text-align: right;">Quantity</th>
              <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle;text-align: right;">Rate</th>
              <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle;text-align: right;">Amount</th>
              <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle;text-align: right;">Prev Balance</th>
              <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle;text-align: right;">Receipt</th>
              <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle;text-align: right;">Current Balance</th>
            </tr>
          </thead>
          <tbody>
            ${Object.keys(CustomerMasterData).map((route, routeIndex) => {
              let routeTotalQty = 0;
              let routeTotalRate = 0;
              let routeTotalAmt = 0;
              let routeTotalPrevBal = 0;
              let routeTotalReceipt = 0;
              let routeTotalCurrentBalance = 0;
  
              const routeRows = CustomerMasterData[route].map((customer, customerIndex) => {
                const qty = Number(customer.qty);
                const rate = !isNaN(customer.amt / customer.qty) ? Number(customer.amt / customer.qty) : 0;
                const amt = Number(customer.amt);
                const prevBal = Number(customer.prev_bal);
                const receipt = Number(customer.cramt);
                const currentBalance = prevBal + amt - receipt;
  
                // Route totals
                routeTotalQty += qty;
                routeTotalRate += rate;
                routeTotalAmt += amt;
                routeTotalPrevBal += prevBal;
                routeTotalReceipt += receipt;
                routeTotalCurrentBalance += currentBalance;
  
                // Grand totals (across all routes)
                totalqtyprint += qty;
                totalrateprint += rate;
                totalamtprint += amt;
                totalprevbalprint += prevBal;
                totalreceiptprint += receipt;
                totalcurrent_balanceprint += currentBalance;
  
                return `
                  <tr>
                    <td style="text-align: left;">${customer.customer_name}</td>
                    <td style="text-align: right;">${qty.toFixed(2)}</td>
                    <td style="text-align: right;">${rate.toFixed(2)}</td>
                    <td style="text-align: right;">${amt.toFixed(2)}</td>
                    <td style="text-align: right;">${prevBal.toFixed(2)}</td>
                    <td style="text-align: right;">${receipt.toFixed(2)}</td>
                    <td style="text-align: right;">${currentBalance.toFixed(2)}</td>
                  </tr>
                `;
              }).join('');
  
              return `
                <React.Fragment key="${routeIndex}">
                  <!-- Route Header -->
                  <tr>
                    <td colSpan="7" style="font-weight: bold; text-align: left; background-color: #f0f0f0;">Route: ${route}</td>
                  </tr>
                  ${routeRows}
                  <!-- Route Total -->
                  <tr style="font-weight: bold; background-color: #e0e0e0">
                    <td style="text-align: left;">Route Total</td>
                    <td style="text-align: right;">${routeTotalQty.toFixed(2)}</td>
                    <td style="text-align: right;">${routeTotalRate.toFixed(2)}</td>
                    <td style="text-align: right;">${routeTotalAmt.toFixed(2)}</td>
                    <td style="text-align: right;">${routeTotalPrevBal.toFixed(2)}</td>
                    <td style="text-align: right;">${routeTotalReceipt.toFixed(2)}</td>
                    <td style="text-align: right;">${routeTotalCurrentBalance.toFixed(2)}</td>
                  </tr>
                </React.Fragment>
              `;
            }).join('')}
  
            <!-- Grand Total -->
            <tr style="font-weight: bold; background-color: #d0d0d0;">
              <td style="text-align: left;">Grand Total</td>
              <td style="text-align: right;">${totalqtyprint.toFixed(2)}</td>
              <td style="text-align: right;">${totalrateprint.toFixed(2)}</td>
              <td style="text-align: right;">${totalamtprint.toFixed(2)}</td>
              <td style="text-align: right;">${totalprevbalprint.toFixed(2)}</td>
              <td style="text-align: right;">${totalreceiptprint.toFixed(2)}</td>
              <td style="text-align: right;">${totalcurrent_balanceprint.toFixed(2)}</td>
            </tr>
  
          </tbody>
        </table>
      </div>
    `);
  
    printWindow.document.close();
  }
  

  const [SearchWithoutRoutename, setSearchWithoutRoutename] = useState(false);
  const handleCheckboxChange = () => {
    setSearchWithoutRoutename(!SearchWithoutRoutename);
  };

    return(
            
      <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', cursor: 'default', fontSize: 20 }} className="text-center"> Monthly CustomerWise Report</h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
          <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
          <ul className="navbar-nav">
              <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
          </div>
      </nav>
  
      <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        {/* <div className="col-md-6 offset-md-3"> */}
          <form method='get'> 

            <div className="row mb-2">
              <div className="col-md-2">
                <label htmlFor="id-centerno" className="form-label"> Center No </label>
                <input type="text" className="form-control" name="centerno" id="id-centerno" value={formData.centerno} onChange={handleInputChange}
                onBlur={centernoonblur} required 
                disabled={
                  UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                  UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                }/>

                {errors.centerno && <span style={{color: 'red', fontSize: '10px'}}>{errors.centerno}</span>}
              </div> 

              <div className="col-md-2">
                <label htmlFor="centerid" className="form-label">Select Center</label>

                <select
                  className="form-select"
                  name="centerid" // <-- should match `formData.uom1` property
                  // value={formData.centerid}
                  onChange={handleInputChange}
                  
                  disabled={
                    UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                    UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                  }
                >
                {/* uom option from selected record when available */}
                {defaultcenter && (
                  <option key={defaultcenter} value={defaultcenter}>
                    {getCenterNameById(defaultcenter)}
                  </option>
                )}

                {/* dynamically fetched options */}
                {CenterMasterData.map((cp) => (
                  defaultcenter !== cp.centerid ?
                    <option key={cp.centerid} value={cp.centerid} >
                      {cp.centername}
                    </option>
                  : null
                ))}
                </select>
              </div>

              {/* <div className="col-md-2"> */}
                {/* <label htmlFor="id-routecode" className="form-label">Line No </label> */}
                <input type="hidden" className="form-control" name="routecode" value={defaultroutename} onChange={handleInputChange} 
                onBlur={routecodeonblur} required />
                
              {/* </div> */}

              <div className="col-md-2">
                <label htmlFor="id-routename" className="form-label">Select Line</label>
                <select
                  className="form-select"
                  id="id-routename" 
                  name="routename"
                  // value={formData.routename}
                  onChange={handleInputChange}
                >

                {defaultroutename && (
                  <option value={defaultroutename}>
                    {defaultroutename}
                  </option>
                )}

                {RouteMasterData.map((r) => (
                  defaultroutename !== r.routename ?
                  <option key={r.routecode} value={`${r.routecode}-${r.routename}`} >
                    {r.routename}
                  </option>
                    : null
                ))}
                </select>
                {errors.routecode && <span style={{color: 'red', fontSize: '10px'}}>{errors.routecode}</span>}
              </div>

              <div className="col-md-2">
                <label htmlFor="id-month" className="form-label">Select Month</label>
                <input type="month" className="form-control" name="month" id="id-month" value={formData.month} onChange={handleInputChange} required/>
                {errors.month && <span style={{color: 'red', fontSize: '10px'}}>{errors.month}</span>}
              </div>

              <div className="col-md-1">
                <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} onClick={(event) => viewonclick(event)}>View</button>
                {/* <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} >View</button> */}
              </div>

              <div className="col-md-1">
                <button className="btn btn-primary w-100" style={{marginTop: '32px', backgroundColor: 'green', borderColor: 'green'}} onClick={printContent}>Print</button>
              </div>
               
            </div> 

            <div className='row'>
              <div className="col-md-2">
                <input className="form-check-input" type="checkbox" checked={SearchWithoutRoutename} onChange={handleCheckboxChange} name="SearchWithoutRoutename" style={{marginTop: '20px'}} />
                <label className="form-check-label" htmlFor="flexCheckDefault" style={{marginTop: '17px', marginLeft: '10px', fontSize: '15px'}}>
                  Show All Lines
                </label>
              </div>
            </div>

          </form>
      </div>


      <div className="row mt-2">
        <div className="col-md-12 table-responsive-xl">
        {/* below table-container code addded to keep table size fixed still its records increases */}
          <div className="table-container" style={{ height: '480px', overflow: 'auto', borderWidth:'0px' }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{backgroundColor:'#009879', color:"white", width: '10%', textAlign: 'left'}}>Customer Name</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'center'}}>Quantity</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'center'}}>Rate</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'center'}}>Amount</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'center'}}>Prev Balance</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'center'}}>Receipt</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'center'}}>Current Balance</th>
                </tr>
              </thead>
              
              <tbody>
              {Object.keys(CustomerMasterData).map((route, routeIndex) => {
                let routeTotalQty = 0;
                let routeTotalRate = 0;
                let routeTotalAmt = 0;
                let routeTotalPrevBal = 0;
                let routeTotalReceipt = 0;
                let routeTotalCurrentBalance = 0;

                return (
                  <React.Fragment key={routeIndex}>
                    {/* Route Header */}
                    <tr>
                      <td colSpan={7} style={{ fontWeight: 'bold', textAlign: 'left', backgroundColor: '#f0f0f0' }}>
                        Route: {route}
                      </td>
                    </tr>
                    
                    {/* Customer Rows */}
                    {CustomerMasterData[route].map((customer, customerIndex) => {
                      const qty = Number(customer.qty);
                      const rate = !isNaN(customer.amt / customer.qty) ? Number(customer.amt / customer.qty) : 0;
                      const amt = Number(customer.amt);
                      const prevBal = Number(customer.prev_bal);
                      const receipt = Number(customer.cramt);
                      const currentBalance = prevBal + amt - receipt;

                      // Update route totals
                      routeTotalQty += qty;
                      routeTotalRate += rate;
                      routeTotalAmt += amt;
                      routeTotalPrevBal += prevBal;
                      routeTotalReceipt += receipt;
                      routeTotalCurrentBalance += currentBalance;

                      // Update overall totals
                      totalqtytable += qty;
                      totalratetable += rate;
                      totalamttable += amt;
                      totalprevbaltable += prevBal;
                      totalreceipttable += receipt;
                      totalcurrent_balancetable += currentBalance;

                      return (
                        <tr key={customer.customer_mp_id}>
                          <td style={{ textAlign: 'left' }}>{customer.customer_name}</td>
                          <td style={{ textAlign: 'center' }}>{qty.toFixed(2)}</td>
                          <td style={{ textAlign: 'center' }}>{rate.toFixed(2)}</td>
                          <td style={{ textAlign: 'center' }}>{amt.toFixed(2)}</td>
                          <td style={{ textAlign: 'center' }}>{prevBal.toFixed(2)}</td>
                          <td style={{ textAlign: 'center' }}>{receipt.toFixed(2)}</td>
                          <td style={{ textAlign: 'center' }}>{currentBalance.toFixed(2)}</td>
                        </tr>
                      );
                    })}

                    {/* Route Totals */}
                    <tr style={{ fontWeight: 'bold', backgroundColor: '#e0e0e0' }}>
                      <td style={{ textAlign: 'left' }}>Route Total</td>
                      <td style={{ textAlign: 'center' }}>{routeTotalQty.toFixed(2)}</td>
                      <td style={{ textAlign: 'center' }}>{routeTotalRate.toFixed(2)}</td>
                      <td style={{ textAlign: 'center' }}>{routeTotalAmt.toFixed(2)}</td>
                      <td style={{ textAlign: 'center' }}>{routeTotalPrevBal.toFixed(2)}</td>
                      <td style={{ textAlign: 'center' }}>{routeTotalReceipt.toFixed(2)}</td>
                      <td style={{ textAlign: 'center' }}>{routeTotalCurrentBalance.toFixed(2)}</td>
                    </tr>
                  </React.Fragment>
                );
              })}

              {/* Overall Totals */}
              <tr style={{ position: 'sticky', bottom: 0, left: 0, zIndex: 111, fontWeight: 'bold', backgroundColor: '#d0d0d0' }}>
                <td style={{ textAlign: 'left' }}>Grand Total</td>
                <td style={{ textAlign: 'center' }}>{totalqtytable.toFixed(2)}</td>
                <td style={{ textAlign: 'center' }}>{totalratetable.toFixed(2)}</td>
                <td style={{ textAlign: 'center' }}>{totalamttable.toFixed(2)}</td>
                <td style={{ textAlign: 'center' }}>{totalprevbaltable.toFixed(2)}</td>
                <td style={{ textAlign: 'center' }}>{totalreceipttable.toFixed(2)}</td>
                <td style={{ textAlign: 'center' }}>{totalcurrent_balancetable.toFixed(2)}</td>
              </tr>
            </tbody>


            </table>
          </div>

        </div>
      </div>

    </div>

    );

}

export default DailyDeliverySheet;